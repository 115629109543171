'use strict';

class ResetPasswordController {
    //start-non-standard
    errors = {};
    submitted = false;
    //end-non-standard

    constructor(Auth, Modal, $state) {
        this.Auth = Auth;
        this.Modal = Modal;
        this.$state = $state;
    }

    reset(form,Modal,$state) {
        this.submitted = true;

        if (form.$valid) {
            this.Auth.resetPassword(this.user.email)
                .then(this.Modal.confirm.info({
                    title: "Reseteo de contraseña",
                    msg: "Se ha enviado un correo para restablecer la contraseña. Si no recibes el correo en unos minutos, por favor, revisa tu carpeta de spam."
                }, function(err) {
                    console.log(err)
                }))               
                .catch(err => {
                    this.errors.other = err.message;
                });   
                 this.$state.go('login');         
        }
      
    }
}

angular.module('mvpcarrefourApp')
    .controller('ResetPasswordController', ResetPasswordController);
